* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

body {
  font-family: Roboto, sans-serif;
}

@media (max-width: 768px) {
  #transaction-table,
  #transaction-table thead,
  #transaction-table tbody {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  #transaction-table thead {
    display: none;
  }

  #transaction-table tbody tr {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  #transaction-table tbody tr td.amount {
    order: 1;
    width: 123px;
  }

  #transaction-table tbody tr td {
    order: 2;
  }

  #transaction-table tbody tr td.note {
    order: 3;
    flex-basis: 100%;
    margin-top: -20px;
    font-size: 0.95rem;
    overflow-wrap: anywhere;
  }

  #transaction-table tbody tr td:not(.note) {
    border: none;
  }

  #transaction-table .pagination .MuiTablePagination-toolbar {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  #transaction-table tbody tr td {
    padding: 8px;
  }

  #transaction-table tbody tr td.note {
    margin-top: 0;
  }

  #transaction-table tbody tr td.code {
    overflow: hidden;
    font-size: 3vw;
  }
}